import { Navigate } from "react-router-dom";
import * as actions from "./actions";
import { useAuth0 } from "@auth0/auth0-react";

const ProtectedRoute = ({ isLoggedIn, children }) => {
    const { loginWithRedirect, isLoading, error, isAuthenticated } = useAuth0();
  const isAuthorized = actions.isAuthorize();
  const isSignInVerificationRoute =
    window.location.pathname === "/SignInVerification";

  // console.log(isAuthorized, "isAuthorized");
  // console.log(isSignInVerificationRoute, "issignverfic");
  // debugger;

  // Redirect to sign-in if the user is not authorized and not on the SignInVerification route
  if (!isAuthorized && !isSignInVerificationRoute) {
    loginWithRedirect();
    return null; // Prevent rendering children while redirecting
  }

  // If authorized or on SignInVerification route, render children
  return children;
};

export default ProtectedRoute;
