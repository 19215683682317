import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as userActions from '../../../actions/index';
import Loader from '../../../components/Utility/Loader';
import getSymbolFromCurrency from 'currency-symbol-map';
import SearchInput from '../../SearchInput';

// Font Awesome Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faList,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        title: 'Title'
      }
    }
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});

class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zero_product: true,
      currentPage: 1,
      product_details: [],
      header_search_loader: false,
      ApiCurrency: getSymbolFromCurrency('RMB'),
      ConversionCurrency: getSymbolFromCurrency('NGN'),
      currencyRate: null,
      searchKeyword: null,
      searchInput: '',
      pages: [1],
      sortOrder: 'default',
      imgUrl: null,
      showPagination: true,
    };
  }

  componentDidMount() {
    console.log('ProductList component mounted');
    const { location } = this.props;
    if(location && location.state) {
      const { results, search } = location.state;
      if(results && results.length > 0) {
        this.setState({
          product_details: results,
          zero_product: false,
          searchKeyword: search
        });
      }else{
        this.setState({
          zero_product: true,
          searchKeyword: search
        });
      }

    }
    const searchParams = new URLSearchParams(window.location.search);
    const searchKeyword = searchParams.get('search');
    const imgUrl = searchParams.get('imgUrl');
    const currentPage = parseInt(searchParams.get('page')) || 1;
    if(imgUrl) {
      this.setState({ imgUrl });
      this.GetProducts(currentPage, null, imgUrl);
    }
    if(searchKeyword){
      this.GetProducts(currentPage, searchKeyword);
      this.setState({ currentPage, searchKeyword });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search!== this.props.location.search) {
      const searchParams = new URLSearchParams(this.props.location.search);
      const searchKeyword = searchParams.get('search');
      const currentPage = parseInt(searchParams.get('page')) || 1;
      if(searchKeyword){
        this.GetProducts(currentPage, searchKeyword);
        this.setState({ currentPage, searchKeyword });
      }
    }
  }

  GetProducts = (pageNo, product_search, imgUrl=null) => {
    if (!product_search && !imgUrl) {
      toast.error('Please add some text or link to search items', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    this.props
      .onGetProduct(product_search, pageNo, null, imgUrl)
      .then((response) => {
        if (response?.data?.success) {
          const productData = response?.data?.response?.data?.data || response?.data?.response?.data || [];
          const page = response?.data?.response?.data?.page_no || false;


          this.setState(
            {
              zero_product: productData.length === 0,
              product_details: productData,
              currencyRate: localStorage.getItem('currencyRate'),
              showPagination: page
            },
            this.applySort
          );
        } else {
          toast.error('No results found with this text', {
            position: toast.POSITION.TOP_RIGHT
          });
          
        }
        this.setState({ loader: false });
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
        toast.error('An error occurred while fetching products.', {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({ loader: false });
      });
  };

  handlePageChange = (direction) => {

    const { currentPage, searchKeyword, imgUrl } = this.state;
    const nextPage = direction === 'next' ? currentPage + 1 : currentPage - 1;

    if (nextPage < 1) return;

    this.setState({ loader: true, currentPage: nextPage }, () => {
      const searchParams = new URLSearchParams(window.location.search);
      const imgUrl = searchParams.get('imgUrl');
      if (imgUrl) {
        this.GetProducts(nextPage, null, imgUrl);
        const queryString = `/search?imgUrl=${imgUrl}&page=${nextPage}`;
        window.history.pushState(null, null, `/product-list${queryString}`);
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        return;
      }

      this.GetProducts(nextPage, searchKeyword);
      const queryString = `/search?search=${searchKeyword}&page=${nextPage}`;
      window.history.pushState(null, null, `/product-list${queryString}`);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  };

  fetchData = (page) => {
    console.log(`Fetching data for page: ${page}`);
    return page;
  };

  renderPagination = () => {
    const { pages, currentPage } = this.state;

    return  (
      <button
        key={currentPage}
        className={`btn mx-0.5 ${
          'active'
        }`}
        onClick={() =>
          this.setState({ currentPage }, () => this.fetchData(currentPage))
        }
      >
        {currentPage}
      </button>
    );
  };

  handleSortChange = (sortOrder) => {
    this.setState({ sortOrder }, this.applySort);
  };

  applySort = () => {
    const { product_details, currencyRate, sortOrder } = this.state;
    if (!product_details || product_details.length === 0 || !currencyRate)
      return;

    const sortedProducts = [...product_details];
    if (sortOrder === 'lowToHigh') {
      sortedProducts.sort(
        (a, b) => a.price * currencyRate - b.price * currencyRate
      );
    } else if (sortOrder === 'highToLow') {
      sortedProducts.sort(
        (a, b) => b.price * currencyRate - a.price * currencyRate
      );
    }

    this.setState({ product_details: sortedProducts });
  };

  render() {
    const {
      product_details,
      zero_product,
      currencyRate,
      ConversionCurrency,
      searchKeyword,
      sortOrder,
      currentPage,
      showPagination
    } = this.state;

    const currentPageData = product_details;

    return (
      <React.Fragment>
        {this.state.is_redirect ? (
          <Navigate
            to={this.state.redirect_url}
            state={this.state.redirect_state}
          />
        ) : null}
        {this.state.loader ? <Loader /> : null}
        {this.props.loader ? <Loader /> : null}
        
	<div className="">
          <div className="row my-8 searchDetails">
            <div style={{ marginTop: '12px' }}>
            <SearchInput />
            </div>
          </div>

          <div className="category-sec">
            <div className="container-fluid">
              <div className="row mb-4">
                <div className="col-12">
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className={`btn ${
                        sortOrder === 'default'
                          ? 'btn-primary'
                          : 'btn-outline-primary'
                      }`}
                      onClick={() => this.handleSortChange('default')}
                    >
                      <FontAwesomeIcon icon={faList} className="me-2" /> Default
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        sortOrder === 'lowToHigh'
                          ? 'btn-primary'
                          : 'btn-outline-primary'
                      }`}
                      onClick={() => this.handleSortChange('lowToHigh')}
                    >
                      <FontAwesomeIcon icon={faArrowUp} className="me-2" /> Low
                      to High
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        sortOrder === 'highToLow'
                          ? 'btn-primary'
                          : 'btn-outline-primary'
                      }`}
                      onClick={() => this.handleSortChange('highToLow')}
                    >
                      <FontAwesomeIcon icon={faArrowDown} className="me-2" />{' '}
                      High to Low
                    </button>
                  </div>
                </div>
              </div>

              <div className="row" id="currentPageData">
                {zero_product &&
                  'NO RESULT FOUND PLEASE TRY AGAIN WITH OTHER KEYWORDS'}
                {currentPageData &&
                  currentPageData.map((item, product_key) => (
                    <div className="col-lg-2 col-md-6" key={product_key}>
                      <div className="product-card">
                        <Link
                          to={`/product-detail/search?${item?.item_id}`}
                          state={{
                            product_detail: item,
                            searchKeyword,
                            currencyRate
                          }}
                          className="product-card__link"
                        >
                          <div className="product-card__image-container">
                            <img
                              src={item?.main_image_url}
                              alt={item?.title || 'Product Image'}
                              className="product-card__image"
                            />
                          </div>
                          <div className="product-card__content">
                            <h2 className="product-card__title">
                              {item?.multi_language_info.title}
                            </h2>
                            <div className="product-card__price">
                              <span className="#product-card__conversion card__amount">
                                {ConversionCurrency}
                                {parseFloat(
                                  item?.price * currencyRate
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                {!zero_product && showPagination && (
                  <div className="pagination">
                    <button
                      className="btn page-link"
                      onClick={() => this.handlePageChange('prev')}
                      disabled={this.state.currentPage === 1}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} className="me-2" />{' '}
                      Previous
                    </button>
                    {this.renderPagination()}
                    <button
                      className="btn page-link"
                      onClick={() => this.handlePageChange('next')}
                    >
                      Next{' '}
                      <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.Auth.loader,
    error: state.Auth.error
  };
}

const mapDispatchToProps = {
  onGetProduct: userActions.Getproduct
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
