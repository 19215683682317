import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import * as userActions from "../../actions/index";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Utility/Loader";
import axios from "./../../axios";

export const Profile = ({ setEmail, setUser, setUserError }) => {
  const { user, isAuthenticated, isLoading, error } = useAuth0();

  React.useEffect(() => {
    console.log('Callback URL:', window.location.href); // Debug callback URL
    if (error) {
      console.error("Auth0 Error:", {
        message: error.message,
        name: error.name,
        stack: error.stack,
        code: error.code,
        fullError: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      });
      setUserError(error.message || "Authentication failed");
      return;
    }
    if (isAuthenticated && user && typeof setEmail === "function") {
      console.log("Authenticated user:", user);
      setEmail(user.email);
      setUser(user);
    }
  }, [isAuthenticated, user, setEmail, setUser, error]);

  return null;
};

class SigninVerifyComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      user: null,
      userError: "",
      redirect: false,
    };
  }

  setEmail = (email) => {
    this.setState({ email });
  };

  setUser = (user) => {
    this.setState({ user }, () => {
      this.handlePostLogin(user);
    });
  };

  setUserError = (error) => {
    this.setState({ userError: error });
  };

  handlePostLogin = async (user) => {
    const { email } = this.state;
    try {
      const formData = new FormData();
      formData.append("user_type", "customer");
      formData.append("email", email);

      const signInResponse = await this.props.onSignIn(formData);
      console.log(signInResponse, "signInResponse");
      if (signInResponse.success) {
        localStorage.setItem("token", signInResponse.token);
        const authUserResponse = await this.props.onGetAuthUser();
        console.log(authUserResponse, "authuserresponse");
        if (authUserResponse.success) {
          toast.success("Logged in successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ redirect: true });
        } else {
          toast.error(authUserResponse.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error(signInResponse.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Post-login error:", error);
    }
  };

  render() {
    const { user, userError, redirect } = this.state;

    if (redirect && user.email_verified) {
      return <Navigate to="/" />;
    }

    if (userError) {
      return (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh", backgroundColor: "#f9f9f9" }}
        >
          <h4 className="text-danger mb-3">Oops! Something went wrong.</h4>
          <p className="text-secondary mb-3">
            It seems like there was an issue with your login. {userError}
          </p>
        </div>
      );
    }

    if (user && !user.email_verified) {
      return (
        <div className="login-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="login-form">
                <div className="max-w-screen-xl mx-auto py-20 flex items-center justify-center main-divv">
                  <div className="col-md-6 flex justify-center">
                    <img
                      src="/assets/images/sign-in.png"
                      alt="Sign In"
                      className="max-w-full main-divv-img"
                    />
                  </div>
                  <div className="col-md-6 flex items-center justify-center h-screen">
                    <div className="w-full lg:w-1/2 mt-10 lg:mt-0 lg:pl-10 main-divv2">
                      <p className="text-xl font-semibold text-gray-700 mb-4">
                        Please verify your email to access the website.
                      </p>
                      <p className="text-lg text-gray-600 mb-4">
                        A verification email has been sent to{" "}
                        <span className="font-semibold text-blue-600">
                          {user?.email}
                        </span>
                        . Please check your inbox and follow the instructions to
                        complete the verification process.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <Profile
          setEmail={this.setEmail}
          setUser={this.setUser}
          setUserError={this.setUserError}
        />
        {!user ? <Loader /> : <div>Login successful, redirecting...</div>}
        <ToastContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loader: state.Auth.loader,
  error: state.Auth.error,
});

const mapDispatchToProps = {
  onSignIn: userActions.SignIn,
  onGetAuthUser: userActions.GetAuthUser,
  onSignUp: userActions.SignUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninVerifyComp);